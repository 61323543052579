import { IUserPreferences } from 'backend/types/userPreferences';
import { COLORS } from 'consts/styles';
import { DashboardCareSiteRole } from 'consts/roles';
import { RoleType } from 'store/roles/types';
import { ISelectItem } from 'components/select';

export interface BaseDashboardProps {
  userPreferences: IUserPreferences;
  setUserPreferences: (value: IUserPreferences) => void;
  refreshDashboard?: number;
  onRefreshDashboard?: () => void;
}

export enum userPreference {
  defaultDashboard = 'DefaultDashboard',
  cmRnCaseLoadDashboardFilter = 'CMRNCaseLoadDashboardFilter',
}

export enum dashBoardType {
  umRnTatDashboard = 'UM TAT Dashboard',
  uMMdDashboard = 'UM MD Dashboard',
  cmManagerDashboard = 'CM Manager Dashboard',
}

export enum dashBoardGridPriority {
  expedited = 'Expedited',
  standard = 'Standard',
}

export enum dashBoardAuthorization {
  all = 'All',
  myOwned = 'My Owned',
}

export enum dashBoardDataColumnNames {
  lessThanZero = 'lessThanZero',
  lessThanTwo = 'lessThanTwo',
  lessThanThree = 'lessThanThree',
  greaterThanThree = 'greaterThanThree',
  mDReviewCount = 'mDReviewCount',
  mDP2PReviewCount = 'mDP2PReviewCount',
  cSRMDCount = 'cSRMDCount',
  mDAppealReviewCount = 'mDAppealReviewCount',
  careSiteName = 'careSiteName',
}

export const mdDashBoardGridColumns = [
  {
    name: dashBoardDataColumnNames.careSiteName,
    title: 'Care Site',
  },
  {
    name: dashBoardDataColumnNames.mDReviewCount,
    title: 'MD Review',
  },
  {
    name: dashBoardDataColumnNames.mDP2PReviewCount,
    title: 'MD P2P Review',
  },
  {
    name: dashBoardDataColumnNames.mDAppealReviewCount,
    title: 'MD Appeal Review',
  },
  {
    name: dashBoardDataColumnNames.cSRMDCount,
    title: 'CSR MD',
  },
];

export const dashBoardGridColumns = [
  {
    name: dashBoardDataColumnNames.careSiteName,
    title: 'Care Site',
  },
  {
    name: dashBoardDataColumnNames.lessThanZero,
    title: '< Zero',
  },
  {
    name: dashBoardDataColumnNames.lessThanTwo,
    title: '1 Day',
  },
  {
    name: dashBoardDataColumnNames.lessThanThree,
    title: '2 Days',
  },
  {
    name: dashBoardDataColumnNames.greaterThanThree,
    title: '3+ Days',
  },
];

export const dashBoardDataColumnsExtensionStyles = [
  {
    columnName: dashBoardDataColumnNames.careSiteName,
    width: '30%',
  },
  {
    columnName: dashBoardDataColumnNames.lessThanZero,
    width: '15%',
    COLOR: COLORS.RED100,
  },
  {
    columnName: dashBoardDataColumnNames.lessThanTwo,
    width: '15%',
  },
  {
    columnName: dashBoardDataColumnNames.lessThanThree,
    width: '15%',
  },
  {
    columnName: dashBoardDataColumnNames.greaterThanThree,
    width: '15%',
  },
];

export const mdDataColumnsExtensionStyles = [
  {
    columnName: dashBoardDataColumnNames.careSiteName,
    width: '30%',
  },
  {
    columnName: dashBoardDataColumnNames.mDReviewCount,
    width: '15%',
  },
  {
    columnName: dashBoardDataColumnNames.mDP2PReviewCount,
    width: '15%',
  },
  {
    columnName: dashBoardDataColumnNames.mDAppealReviewCount,
    width: '15%',
  },
  {
    columnName: dashBoardDataColumnNames.cSRMDCount,
    width: '15%',
  },
];

export const totalTatSummaryItems = [
  { columnName: dashBoardDataColumnNames.lessThanZero, type: 'sum' },
  { columnName: dashBoardDataColumnNames.lessThanTwo, type: 'sum' },
  { columnName: dashBoardDataColumnNames.lessThanThree, type: 'sum' },
  { columnName: dashBoardDataColumnNames.greaterThanThree, type: 'sum' },
  { columnName: dashBoardDataColumnNames.careSiteName, type: 'avg' }, // For Total Label purpose in careSiteName column.
];

export const totalMdSummaryItems = [
  { columnName: dashBoardDataColumnNames.mDReviewCount, type: 'sum' },
  { columnName: dashBoardDataColumnNames.mDP2PReviewCount, type: 'sum' },
  { columnName: dashBoardDataColumnNames.mDAppealReviewCount, type: 'sum' },
  { columnName: dashBoardDataColumnNames.cSRMDCount, type: 'sum' },
  { columnName: dashBoardDataColumnNames.careSiteName, type: 'avg' }, // For Total Label purpose in careSiteName column.
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTotalRowValue = (value: any): any => {
  if (isNaN(value)) {
    return 'Totals:';
  } else if (value === 0) {
    return '-';
  } else {
    return value;
  }
};

export const getDashboardsByRole = (
  roleId: number,
  roleType: RoleType | undefined
): ISelectItem[] => {
  const isDashboardAccess = roleId == DashboardCareSiteRole.roleId;
  if (isDashboardAccess) {
    return [
      {
        id: dashBoardType.cmManagerDashboard,
        name: dashBoardType.cmManagerDashboard,
      },
      {
        id: dashBoardType.umRnTatDashboard,
        name: dashBoardType.umRnTatDashboard,
      },
      {
        id: dashBoardType.uMMdDashboard,
        name: dashBoardType.uMMdDashboard,
      },
    ];
  }
  if (roleType === RoleType.CLINICAL) {
    return [
      {
        id: dashBoardType.cmManagerDashboard,
        name: dashBoardType.cmManagerDashboard,
      },
    ];
  } else if (roleType === RoleType.NONCLINICAL) {
    return [
      {
        id: dashBoardType.umRnTatDashboard,
        name: dashBoardType.umRnTatDashboard,
      },
      {
        id: dashBoardType.uMMdDashboard,
        name: dashBoardType.uMMdDashboard,
      },
    ];
  }
  return [];
};

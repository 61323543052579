import React, { forwardRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { Box, Typography, MenuItem, Button } from '@mui/material';
import { COLORS } from 'consts/styles';
import Menu from 'components/menu';
import { DetailedMenuItem } from 'components/menu/DetailedMenuItem';
import { ICONS } from 'components/icon';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { Privilege } from 'store/roles/types';
import styled from 'styled-components';

const StyledTitle = styled(Typography)({
  display: 'inline',
  color: COLORS.GREY100,
});

const StyledMenuItem = styled(MenuItem)({
  '&.MuiMenuItem-root': {
    width: '448px',
  },
});

interface ISearchSuggestionsProps {
  isRecentOpen: boolean;
  handleSubmit: (
    e: React.MouseEvent<HTMLButtonElement | MouseEvent | HTMLLIElement>,
    searchTerm: string
  ) => void;
  handleClose: (closed: boolean) => void;
  setAnchorEl: React.Dispatch<React.SetStateAction<Element | null>>;
  setShowPopover: (event: React.MouseEvent<HTMLButtonElement>) => void;
  recentSearches: string[];
  inputValue: string;
  hasAdvancedSearchPrivilege: boolean;
  setIsAdvancedSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ISearchSuggestionsFooterProps {
  handleClose: (closed: boolean) => void;
  setShowAdvancedSearch: (event: React.MouseEvent<HTMLButtonElement>) => void;
  hasAdvancedSearchPrivilege: boolean;
  setIsAdvancedSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

const MenuHeader = ({ isMatchFound }: { isMatchFound: boolean }) => (
  <Box padding="8px 20px">
    <Typography variant="subtitle1">Recent Searches</Typography>
    {!isMatchFound && (
      <Typography variant="body2">
        <br />
        No recent items match your search.
        <br />
        Press ENTER to see all search results.
      </Typography>
    )}{' '}
  </Box>
);

const MenuFooter = (props: ISearchSuggestionsFooterProps) => {
  const {
    handleClose,
    setShowAdvancedSearch,
    hasAdvancedSearchPrivilege,
    setIsAdvancedSearch,
  } = props;
  const allowAnyPHISearch = hasUserPrivilege(Privilege.AllowAnyPHISearch);
  const advancedSearch = hasUserPrivilege(Privilege.AdvancedSearch);
  const title =
    allowAnyPHISearch && !advancedSearch
      ? 'Last Name, First Name, Date of Birth, MBR ID, Medicaid ID, HICN ID, MRN4, Episode ID'
      : 'MBR ID, Auth ID';
  return (
    <Box
      padding="16px 20px 8px 20px"
      borderTop={`1px solid ${COLORS.GREY25}`}
      marginTop="12px"
    >
      {!hasAdvancedSearchPrivilege && (
        <div>
          <StyledTitle variant="body2">Search by </StyledTitle>
          <StyledTitle
            variant="body1"
            data-testid="MenuFooterSearchDescription"
          >
            {title}
          </StyledTitle>
        </div>
      )}
      {hasAdvancedSearchPrivilege && (
        <Button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            handleClose(false);
            setIsAdvancedSearch(true);
            setShowAdvancedSearch(event);
          }}
          variant="text"
          color="primary"
          size="small"
          data-cy="cancel-provider-filters"
        >
          Advanced Search
        </Button>
      )}
    </Box>
  );
};

export const SearchSuggestions = forwardRef<
  HTMLDivElement,
  ISearchSuggestionsProps
>(
  (
    {
      isRecentOpen,
      handleSubmit,
      handleClose,
      setAnchorEl,
      setShowPopover,
      recentSearches,
      hasAdvancedSearchPrivilege,
      setIsAdvancedSearch,
    },
    ref
  ) => {
    const anchor =
      ref != null && typeof ref !== 'function'
        ? (ref.current as Element)
        : null;
    const searchValue = useSelector((state: IState) => state.ui.header.search);
    const [filteredSearchSuggestions, setFilteredSearchSuggestions] =
      useState(recentSearches);
    const [isMatchFound, setIsMatchFound] = useState(true);

    const handleFilteringSearchSuggestions = (
      searchValue: string | undefined,
      recentSearches: string[]
    ) => {
      if (!searchValue) {
        setFilteredSearchSuggestions(recentSearches);
      } else {
        const filteredRecentSearches = recentSearches.filter((item) =>
          item.toLowerCase().startsWith(searchValue.toLowerCase())
        );
        setFilteredSearchSuggestions(filteredRecentSearches);
      }
    };

    useEffect(() => {
      handleFilteringSearchSuggestions(searchValue, recentSearches);
    }, [searchValue, recentSearches]);

    useEffect(() => {
      if (filteredSearchSuggestions.length === 0) {
        setIsMatchFound(false);
      } else {
        setIsMatchFound(true);
      }
    }, [filteredSearchSuggestions.length]);

    const filterRecentSearches = (item: string) => {
      if (!searchValue) {
        return true;
      }
      return item.toLowerCase().startsWith(searchValue.toLowerCase());
    };
    const highlightMatchedText = (item: string) => {
      return (
        <>
          <span style={{ fontWeight: 'bold' }}>
            {item.slice(0, searchValue.length)}
          </span>
          {item.slice(searchValue.length)}
        </>
      );
    };
    setAnchorEl(anchor);
    return (
      <Menu
        open={isRecentOpen}
        onClose={() => handleClose(false)}
        anchor={anchor}
        header={<MenuHeader isMatchFound={isMatchFound} />}
        style={{ width: '448px' }}
        footer={
          <MenuFooter
            handleClose={handleClose}
            setShowAdvancedSearch={setShowPopover}
            setIsAdvancedSearch={setIsAdvancedSearch}
            hasAdvancedSearchPrivilege={hasAdvancedSearchPrivilege}
          />
        }
      >
        {recentSearches?.filter(filterRecentSearches).map((item) => (
          <StyledMenuItem key={item} onClick={(e) => handleSubmit(e, item)}>
            <DetailedMenuItem
              details={
                searchValue && isMatchFound ? highlightMatchedText(item) : item
              }
              icon={ICONS.Update}
              iconColor={COLORS.GREY100}
            />
          </StyledMenuItem>
        ))}
      </Menu>
    );
  }
);
SearchSuggestions.displayName = 'SearchSuggestions';

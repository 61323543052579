import React from 'react';
import { useDispatch } from 'react-redux';
import Link from '@mui/material/Link';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import { openDashBoardSearch } from 'store/home/action';
import { IDashBoardSearchCriteria } from 'backend/types/dashBoardSearchCriteria';
import { Typography } from '@mui/material';
import {
  dashBoardAuthorization,
  dashBoardType,
} from '../Dashboard/DashBoardGridHelper';

interface CountLinkFormatterProps extends DataTypeProvider.ValueFormatterProps {
  priority: string | null;
  defaultAuthorization: dashBoardAuthorization;
  dashBoardType: dashBoardType;
}

const CountLink = styled(Link)({
  width: '120px',
  color: COLORS.SYMPHONY_BLUE,
  cursor: 'pointer',
  fontWeight: 500,
  lineHeight: '20px',
});

const CountLinkFormatter = (props: CountLinkFormatterProps) => {
  const dispatch = useDispatch();

  const getUserId = () => {
    const userId = String(props.row?.userId ?? '');
    if (props.dashBoardType === dashBoardType.cmManagerDashboard) {
      return userId || null;
    }
    return null;
  };

  const handleCountClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const dashBoardSearchCriteria: IDashBoardSearchCriteria = {
      dashBoardType: props.dashBoardType,
      defaultAuthorization:
        props.defaultAuthorization ?? dashBoardAuthorization.all,
      nonClinicalPriority: props.priority,
      columnName: props.column.name,
      owner: getUserId(),
    };

    dispatch(openDashBoardSearch(dashBoardSearchCriteria));
  };

  return props.value > 0 ? (
    <CountLink onClick={handleCountClick}>
      <span>{props.value}</span>
    </CountLink>
  ) : (
    <Typography>{props.value === 0 ? '-' : props.value}</Typography>
  );
};

export default CountLinkFormatter;

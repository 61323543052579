import { EpisodeType, EpisodeViewModel } from 'graphql/graphqlTypes';
import { IEpisodesTabState } from './types';
import { EpisodeListState } from 'backend/types/episodeListState';
import { OPEN_CLOSE_STATUS } from 'components/constants';
export type EpisodeTypes = 'cases' | 'episodes' | 'orgDet' | 'appealgriev';

export const columnsExtension = [
  { columnName: 'episodeNumber', sortingEnabled: true, width: '8%' },
  { columnName: 'tat', sortingEnabled: true, width: '8%' },
  { columnName: 'counter', sortingEnabled: true, width: '8%' },
  { columnName: 'createdOn', sortingEnabled: true, width: '8%' },
  { columnName: 'episodePathway', sortingEnabled: false, width: '7%' },
  { columnName: 'type', sortingEnabled: true, width: '7%' },
  { columnName: 'authStatus', sortingEnabled: true, width: '8%' },
  { columnName: 'authorizationCategory', sortingEnabled: true, width: '7%' },
  { columnName: 'authorizationStartDate', sortingEnabled: true, width: '7%' },
  { columnName: 'anyProgress', sortingEnabled: true, width: '7%' },
  { columnName: 'updatedBy', sortingEnabled: true, width: '7%' },
  { columnName: 'nextStep', sortingEnabled: true, width: '8%' },
  { columnName: 'workFlowInstanceId', sortingEnabled: true, width: '3%' },
  { columnName: 'state', sortingEnabled: false, width: '7%' },
];

export const appealGrievColumnsExtension = [
  { columnName: 'episodeNumber', sortingEnabled: true, width: '8%' },
  { columnName: 'tat', sortingEnabled: true, width: '8%' },
  { columnName: 'createdOn', sortingEnabled: true, width: '8%' },
  { columnName: 'type', sortingEnabled: true, width: '7%' },
  { columnName: 'status', sortingEnabled: true, width: '9%' },
  { columnName: 'requestedBy', sortingEnabled: true, width: '7%' },
  { columnName: 'incidentRegulatory', sortingEnabled: false, width: '7%' },
  { columnName: 'medicarePartType', sortingEnabled: false, width: '7%' },
  { columnName: 'authorizationVendor', sortingEnabled: false, width: '7%' },
  { columnName: 'faxDateTime', sortingEnabled: true, width: '8%' },
  { columnName: 'anyProgress', sortingEnabled: true, width: '7%' },
  { columnName: 'updatedBy', sortingEnabled: true, width: '7%' },
  { columnName: 'nextStep', sortingEnabled: true, width: '8%' },
  { columnName: 'workFlowInstanceId', sortingEnabled: true, width: '3%' },
  { columnName: 'state', sortingEnabled: false, width: '7%' },
];

export const orgDeterminationColumnsExtension = [
  { columnName: 'createdOn', sortingEnabled: true, width: '7%' },
  { columnName: 'episodeNumber', sortingEnabled: true, width: '11%' },
  { columnName: 'tat', sortingEnabled: true, width: '7%' },
  { columnName: 'type', sortingEnabled: true, width: '16%' },
  { columnName: 'authStatus', sortingEnabled: true, width: '13%' },
  { columnName: 'anyProgress', sortingEnabled: true, width: '10%' },
  { columnName: 'state', sortingEnabled: false, width: '7%' },
];

export const duplicateEpisodeColumnsExtension = [
  { columnName: 'createdOn', sortingEnabled: true, width: '7%' },
  { columnName: 'episodeNumber', sortingEnabled: true, width: '11%' },
  { columnName: 'type', sortingEnabled: false, width: '12%' },
  { columnName: 'createdBy', sortingEnabled: false, width: '15%' },
  { columnName: 'servicingProvider', sortingEnabled: false, width: '17%' },
  { columnName: 'facility', sortingEnabled: false, width: '18%' },
  { columnName: 'procedureCode', sortingEnabled: false, width: '7%' },
  { columnName: 'authStatus', sortingEnabled: false, width: '13%' },
];

export const openedColumnsExtension = [
  { columnName: 'episodeNumber', sortingEnabled: true, width: '35%' },
  { columnName: 'tat', sortingEnabled: true, width: '15%' },
  { columnName: 'authStatus', sortingEnabled: true, width: '25%' },
  { columnName: 'anyProgress', sortingEnabled: true, width: '25%' },
];

export const appealGrievOpenedColumnsExtension = [
  { columnName: 'episodeNumber', sortingEnabled: true, width: '35%' },
  { columnName: 'faxDateTime', sortingEnabled: true, width: '15%' },
  { columnName: 'authStatus', sortingEnabled: true, width: '25%' },
  { columnName: 'anyProgress', sortingEnabled: true, width: '25%' },
];

export const casesColumnsExtension = [
  { columnName: 'episodeNumber', sortingEnabled: true, width: '10%' },
  { columnName: 'counter', sortingEnabled: true, width: '9%' },
  { columnName: 'createdOn', sortingEnabled: true, width: '8%' },
  { columnName: 'program', sortingEnabled: true, width: '15%' },
  { columnName: 'careStage', sortingEnabled: true, width: '10%' },
  { columnName: 'referralDate', sortingEnabled: true, width: '10%' },
  { columnName: 'engagedDate', sortingEnabled: true, width: '10%' },
  { columnName: 'anyProgress', sortingEnabled: true, width: '10%' },
  { columnName: 'owner', sortingEnabled: true, width: '10%' },
  { columnName: 'state', sortingEnabled: false, width: '8%' },
];

export const casesOpenedColumnsExtension = [
  { columnName: 'episodeNumber', sortingEnabled: true, width: '25%' },
  { columnName: 'counter', sortingEnabled: true, width: '10%' },
  { columnName: 'program', sortingEnabled: true, width: '25%' },
  { columnName: 'referralDate', sortingEnabled: true, width: '20%' },
  { columnName: 'owner', sortingEnabled: true, width: '20%' },
];

export const patientSearchColumnsExtension = [
  { columnName: 'createdOn', sortingEnabled: true, width: '10%' },
  { columnName: 'episodeNumber', sortingEnabled: true, width: '12%' },
  { columnName: 'authorizationStartDate', sortingEnabled: true, width: '10%' },
  { columnName: 'type', sortingEnabled: true, width: '16%' },
  { columnName: 'updatedBy', sortingEnabled: true, width: '15%' },
  { columnName: 'authStatus', sortingEnabled: true, width: '13%' },
  { columnName: 'anyProgress', sortingEnabled: true, width: '10%' },
  { columnName: 'state', sortingEnabled: false, width: '14%' },
];

export const openedPatientSearchColumnsExtension = [
  { columnName: 'createdOn', sortingEnabled: true, width: '35%' },
  { columnName: 'episodeNumber', sortingEnabled: true, width: '35%' },
  { columnName: 'authorizationStartDate', sortingEnabled: true, width: '30%' },
];

const getEpisodeColumns = () => {
  return [
    { name: 'episodeNumber', title: 'ID' },
    { name: 'tat', title: 'TAT' },
    { name: 'counter', title: 'Counter' },
    { name: 'createdOn', title: 'Created' },
    { name: 'episodePathway', title: 'Type' },
    { name: 'type', title: 'Auth Type' },
    { name: 'authStatus', title: 'Status' },
    { name: 'authorizationCategory', title: 'Category' },
    { name: 'authorizationStartDate', title: 'Start Date' },
    {
      name: 'anyProgress',
      title: 'Progress',
      getCellValue: (row: EpisodeViewModel) => ({
        topReminder: row.topReminder,
        progress: row.anyProgress,
      }),
    },
    { name: 'updatedBy', title: 'Updated By' },
    { name: 'nextStep', title: 'Next Step' },
    { name: 'workFlowInstanceId', title: 'Actions' },
    {
      name: 'state',
      title: ' ',
      getCellValue: (row: EpisodeViewModel) => ({
        state: row.state,
      }),
    },
  ];
};

const getAppealGrievColumns = () => {
  return [
    { name: 'episodeNumber', title: 'ID' },
    { name: 'tat', title: 'TAT' },
    { name: 'createdOn', title: 'Created' },
    { name: 'type', title: 'Type' },
    { name: 'status', title: 'Status' },
    { name: 'requestedBy', title: 'Requestor' },
    { name: 'incidentRegulatory', title: 'Regulated' },
    { name: 'medicarePartType', title: 'Medicare Part' },
    { name: 'authorizationVendor', title: 'Vendor' },
    { name: 'faxDateTime', title: 'Receipt Date' },
    {
      name: 'anyProgress',
      title: 'Progress',
      getCellValue: (row: EpisodeViewModel) => ({
        topReminder: row.topReminder,
        progress: row.anyProgress,
      }),
    },
    { name: 'updatedBy', title: 'Updated By' },
    { name: 'nextStep', title: 'Next Step' },
    { name: 'workFlowInstanceId', title: 'Actions' },
    {
      name: 'state',
      title: ' ',
      getCellValue: (row: EpisodeViewModel) => ({
        state: row.state,
      }),
    },
  ];
};

export const getAppealGrievOpenedColumns = () => [
  { name: 'episodeNumber', title: 'Episode ID' },
  { name: 'tat', title: 'TAT' },
  { name: 'status', title: 'Status' },
  {
    name: 'anyProgress',
    title: 'Progress',
    getCellValue: (row: EpisodeViewModel) => ({
      topReminder: row.topReminder,
      progress: row.anyProgress,
    }),
  },
];

export const moveFlowColumnsExtension = [
  { columnName: 'createdOn', sortingEnabled: true, width: '35%' },
  { columnName: 'episodeNumber', sortingEnabled: true, width: '35%' },
  { columnName: 'episodePathway', sortingEnabled: true, width: '30%' },
];

export const getDuplicateEpisodeColumns = () => {
  return [
    { name: 'createdOn', title: 'Created' },
    { name: 'episodeNumber', title: 'ID' },
    { name: 'type', title: 'Auth Type' },
    { name: 'createdBy', title: 'Created By' },
    { name: 'servicingProvider', title: 'Servicing Provider' },
    { name: 'facility', title: 'Facility' },
    { name: 'procedureCode', title: 'CPT Code' },
    { name: 'authStatus', title: 'Status' },
  ];
};

export const getCaseColumns = () => {
  return [
    { name: 'episodeNumber', title: 'Episode ID' },
    { name: 'counter', title: 'Counter' },
    { name: 'createdOn', title: 'Created' },
    {
      name: 'program',
      title: 'Program',
    },
    {
      name: 'careStage',
      title: 'Care Stage',
    },
    { name: 'referralDate', title: 'Referral Date' },
    { name: 'engagedDate', title: 'Engaged Date' },
    {
      name: 'anyProgress',
      title: 'Progress',
      getCellValue: (row: EpisodeViewModel) => ({
        topReminder: row.topReminder,
        progress: row.anyProgress,
      }),
    },
    { name: 'owner', title: 'Owner' },
    {
      name: 'state',
      title: OPEN_CLOSE_STATUS,
      getCellValue: (row: EpisodeViewModel) => ({
        state: row.state,
      }),
    },
  ];
};

export const getPatientSearchColumns = () => {
  return [
    { name: 'createdOn', title: 'Created' },
    { name: 'episodeNumber', title: 'ID' },
    { name: 'authorizationStartDate', title: 'Start Date' },
    { name: 'type', title: 'Type' },
    { name: 'updatedBy', title: 'Updated By' },
    { name: 'authStatus', title: 'Status' },
    {
      name: 'anyProgress',
      title: 'Progress',
      getCellValue: (row: EpisodeViewModel) => ({
        topReminder: row.topReminder,
        progress: row.anyProgress,
      }),
    },
    {
      name: 'state',
      title: OPEN_CLOSE_STATUS,
    },
  ];
};

export const getColumns = (
  episodeType: EpisodeTypes,
  showAllTypes: boolean
) => {
  if (showAllTypes) {
    return getPatientSearchColumns();
  }
  switch (episodeType) {
    case 'orgDet':
      return getEpisodeColumns();
    case 'cases':
      return getCaseColumns();
    case 'appealgriev':
      return getAppealGrievColumns();
    default:
      return getEpisodeColumns();
  }
};

export const mapSubTabKeys = (key: string | null | undefined) => {
  switch (key) {
    case null:
    case undefined:
    case '0':
    case '':
      return 'Details';
    case '1':
      return 'Documents';
    case '2':
      return 'CarePlan';
    case '3':
      return 'Attachments';
    case '4':
      return 'Reminders';
    case '5':
      return 'TAT (Auth)';
    default:
      return key;
  }
};

type GetEpisodes = {
  page: number;
  pageSize: number;
  patientId: number;
  orderBy: string;
  ascDesc: string;
  state: string;
  types: string;
  selectedEpisode: number | undefined;
  readonly?: boolean;
  showTatColumn?: boolean;
  showCounterColumn?: boolean;
  checklistId?: number;
};

export const getRequest = (
  episodeTabModel: IEpisodesTabState,
  patientId: number,
  showAllTypes: undefined | boolean,
  showTatColumn: boolean,
  showCounterColumn: boolean,
  readonly: undefined | boolean,
  episodeType: EpisodeTypes
) => {
  const request: GetEpisodes = {
    page: Number(episodeTabModel.page ?? 0),
    pageSize: Number(episodeTabModel.pageSize ?? 15),
    patientId: Number(patientId),
    orderBy: episodeTabModel.orderBy ?? 'createdOn',
    ascDesc: episodeTabModel.ascDesc ?? 'desc',
    state: episodeTabModel.episodeListState ?? EpisodeListState.Open,
    types: showAllTypes
      ? ''
      : episodeTabModel?.episodeTypes
          ?.map((x: EpisodeType) => x.id)
          .join(';') ?? '',
    selectedEpisode:
      episodeTabModel.isDirectOpen && episodeTabModel.selectEpisode
        ? episodeTabModel.selectEpisode
        : undefined,
  };

  if (episodeType !== 'cases') {
    request.checklistId = 0;
    request.showTatColumn = episodeTabModel.showTatColumn ?? showTatColumn;
    request.showCounterColumn =
      episodeTabModel.showCounterColumn ?? showCounterColumn;
    request.readonly = readonly;
  }

  return request;
};

export const getEpisodesOpenedColumns = () => [
  { name: 'episodeNumber', title: 'Episode ID' },
  { name: 'tat', title: 'TAT' },
  { name: 'authStatus', title: 'Status' },
  {
    name: 'anyProgress',
    title: 'Progress',
    getCellValue: (row: EpisodeViewModel) => ({
      topReminder: row.topReminder,
      progress: row.anyProgress,
    }),
  },
];

export const getCasesOpenedColumns = () => [
  { name: 'episodeNumber', title: 'Episode ID' },
  { name: 'counter', title: 'Counter' },
  { name: 'program', title: 'Program' },
  { name: 'referralDate', title: 'Referral Date' },
  { name: 'owner', title: 'Owner' },
];

export const getPatientSearchOpenedColumns = () => [
  { name: 'createdOn', title: 'Created' },
  { name: 'episodeNumber', title: 'ID' },
  { name: 'authorizationStartDate', title: 'Start Date' },
];

export const getOpenedColumns = (
  episodeType: 'episodes' | 'orgDet' | 'cases' | 'appealgriev',
  showAllTypes?: boolean
) => {
  if (showAllTypes) {
    return getPatientSearchOpenedColumns();
  }

  if (episodeType === 'appealgriev') {
    return getAppealGrievOpenedColumns();
  }

  return episodeType === 'cases'
    ? getCasesOpenedColumns()
    : getEpisodesOpenedColumns();
};

export const getColumnExtensions = (
  episodeType: EpisodeTypes,
  showAllTypes: boolean
) =>
  showAllTypes
    ? patientSearchColumnsExtension
    : mapColumnExtensions(episodeType);

export const getOpenedColumnsExtension = (
  episodeType: EpisodeTypes,
  showAllTypes?: boolean
) =>
  showAllTypes
    ? openedPatientSearchColumnsExtension
    : mapOpenedColumnExtensions(episodeType);

const mapColumnExtensions = (episodeType: string) => {
  switch (episodeType) {
    case 'orgDet':
      return orgDeterminationColumnsExtension;
    case 'cases':
      return casesColumnsExtension;
    case 'appealgriev':
      return appealGrievColumnsExtension;
    default:
      return columnsExtension;
  }
};

const mapOpenedColumnExtensions = (episodeType: string) => {
  if (episodeType === 'appealgriev') {
    return appealGrievOpenedColumnsExtension;
  }

  return episodeType === 'cases'
    ? casesOpenedColumnsExtension
    : openedColumnsExtension;
};

export enum ClockCounterType {
  TAT = 'TAT',
  Counter = 'COUNTER',
}

import React, { useEffect, useRef } from 'react';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import { Maybe } from 'graphql/graphqlTypes';
import { useGetNoteAutocompleteEntriesQuery } from 'graphql/hooks/getNoteAutocompleteEntries';
import { COLORS } from 'consts/styles';

interface AutocompleteInput {
  disabled: boolean;
  userDefinedId?: Maybe<string>;
  dataTestid: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  onItemSelected: (value: string) => void;
  error: boolean;
  inputProps: { [k: string]: any };
  key: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Item = ({ entity }: any) => <div>{`${entity}`}</div>;

const AutocompleteInput = (props: AutocompleteInput) => {
  const {
    disabled,
    dataTestid,
    userDefinedId,
    value,
    onChange,
    onBlur,
    onItemSelected,
    error,
    inputProps,
    key,
  } = props;
  const textareaRef = useRef<HTMLTextAreaElement>();

  const { data } = useGetNoteAutocompleteEntriesQuery();

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.border = error
        ? '1px solid red'
        : '1px solid ' + COLORS.GREY30;
    }
  }, [error]);

  const dataProvider = (token: string) =>
    data?.getNoteAutocompleteEntries
      ?.filter(
        (x) =>
          x?.searchText.toLowerCase().includes(token.toLowerCase()) === true
      )
      .map((x) => x?.searchText ?? '') ?? [];

  return (
    <div className="container" key={key} style={{ paddingRight: '25px' }}>
      <ReactTextareaAutocomplete
        value={value}
        disabled={disabled}
        readOnly={inputProps.readOnly}
        maxLength={inputProps.maxLength}
        minLength={inputProps.minLength}
        onBlur={onBlur}
        onChange={(e) => {
          onChange(e as never);
        }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onItemSelected={(event: { currentTrigger: string; item: any }) => {
          onItemSelected(event.item);
        }}
        loadingComponent={() => <span>Loading</span>}
        dropdownStyle={{
          width: '400px',
          zIndex: 99999,
          fontFamily: 'roboto',
          fontWeight: 400,
          fontSize: '14px',
          borderRadius: '4px',
        }}
        style={{
          height: '20px',
          resize: 'none',
          fontFamily: 'roboto',
          fontWeight: 400,
          fontSize: '14px',
          borderRadius: '4px',
          padding: '6px 12px',
        }}
        innerRef={(textarea: HTMLTextAreaElement) => {
          textareaRef.current = textarea;
        }}
        trigger={{
          '.': {
            allowWhitespace: true,
            afterWhitespace: true,
            dataProvider: dataProvider,
            component: Item,
          },
        }}
        {...(userDefinedId && {
          'data-userdefinedid': userDefinedId,
        })}
        data-testid={dataTestid}
      />
    </div>
  );
};
export default AutocompleteInput;

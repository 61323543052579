import React, { CSSProperties, useCallback, useEffect } from 'react';
import { Grid as TableGrid } from '@devexpress/dx-react-grid-material-ui';
import {
  ChecklistInProgressProvider,
  DocumentActionsProvided,
  SituationProvider,
  TextProvider,
} from './DocumentProviders';
import Information from 'components/modal/Information';
import DocumentAttachments from './DocumentAttachments';
import {
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import { useGetEpisodeDocumentsQuery } from 'graphql/hooks/getEpisodeDocuments';
import {
  Attachment,
  ChecklistViewType,
  EpisodesTabChecklist,
} from 'graphql/graphqlTypes';
import Loader from 'components/loader';
import EpisodesDateTimeProvider from 'components/home/grid/providers/EpisodesDateTimeProvider';
import { EpisodeTypes } from '../consts';
import { useDispatch } from 'react-redux';
import { setChecklistIds } from 'store/ui/modals/checklist';

export interface IDocumentsTabProps {
  style?: CSSProperties;
  episodeId: number;
  editingChecklistId?: string;
  readonly?: boolean;
  episodeType: EpisodeTypes;
}

const Documents = (props: IDocumentsTabProps) => {
  const { episodeId, editingChecklistId, style, episodeType } = props;
  const dispatch = useDispatch();

  // need both to disable blinking
  const [showAttachmentsForChecklistId, setShowAttachmentsForChecklistId] =
    React.useState(0);
  const [showAttachments, setShowAttachments] = React.useState(false);

  const columns = [
    {
      name: 'started',
      title: 'Created',
      getCellValue: (row: EpisodesTabChecklist) => row?.checklist?.createdOn,
    },
    {
      name: 'finished',
      title: 'Finished',
      getCellValue: (row: EpisodesTabChecklist) => row?.checklist?.finishedOn,
    },
    {
      name: 'situation',
      title: 'Situation',
      getCellValue: (row: EpisodesTabChecklist) => ({
        checklistId: row?.checklist?.id ?? 0,
        setShowAttachmentsForChecklistId,
        setShowAttachments,
      }),
    },
    {
      name: 'author',
      title: 'Created By',
      getCellValue: (row: EpisodesTabChecklist) =>
        row?.checklist?.createdByUser?.fullName,
    },
    { name: 'progress', title: 'Progress' },
    {
      name: 'operations',
      title: 'Actions',
      getCellValue: () => ({
        refetch,
      }),
    },
  ];

  const columnsExtension = [
    { columnName: 'started', sortingEnabled: false, width: '15%' },
    { columnName: 'finished', sortingEnabled: false, width: '15%' },
    {
      columnName: 'situation',
      sortingEnabled: false,
      width: '25%',
    },
    { columnName: 'author', sortingEnabled: false, width: '15%' },
    { columnName: 'progress', sortingEnabled: false, width: '10%' },
    { columnName: 'operations', sortingEnabled: false, width: '20%' },
  ];

  const { data, isFetching, refetch } = useGetEpisodeDocumentsQuery(
    {
      episodeId: episodeId,
      viewType:
        episodeType === 'cases'
          ? ChecklistViewType.HistoryTab
          : ChecklistViewType.EpisodeTab,
    },
    { skip: episodeId <= 0 }
  );

  let rows = data?.getEpisodeDocuments ?? [];
  if (editingChecklistId) {
    const checklistId = Number(editingChecklistId);
    rows = rows.map((s) => {
      if (s?.checklist?.id === checklistId && s.operations) {
        return { ...s, operations: s.operations.filter((m) => m !== 'DELETE') };
      }

      return s;
    });
  }

  const checklistIds = rows.map((row) => row?.checklist?.id).filter(Boolean);

  useEffect(() => {
    if (checklistIds.length > 0) {
      dispatch(setChecklistIds(checklistIds));
    }
  }, [checklistIds, dispatch]);

  const handleClose = useCallback(() => {
    setShowAttachments(false);
  }, [setShowAttachments]);

  return (
    <div style={style}>
      <TableGrid columns={columns} rows={rows}>
        <EpisodesDateTimeProvider for={['started', 'finished']} />
        <SituationProvider for={['situation']} />
        <TextProvider for={['author']} />
        <ChecklistInProgressProvider for={['progress']} />
        <DocumentActionsProvided for={['operations']} />
        <SymphonyTable columnExtensions={columnsExtension} />
        <SymphonyTableHeaderRow showSortingControls={false} />
      </TableGrid>
      <Information
        open={showAttachments}
        okEvent={handleClose}
        title="Attachments"
      >
        <DocumentAttachments
          attachments={
            (data?.getEpisodeDocuments && data?.getEpisodeDocuments.length > 0
              ? (data?.getEpisodeDocuments ?? []).find(
                  (item) =>
                    item?.checklist?.id === showAttachmentsForChecklistId
                )?.checklist?.attachments
              : []) as Attachment[]
          }
        />
      </Information>
      <Loader active={isFetching} />
    </div>
  );
};

export default Documents;
